export default {
    portfolioBattle: 'Portfolio<br>Battle',
    startPlaceholder: 'Enter email to begin the battle',
    hi: 'Hi',
    portfolioNameInfo: 'Come up with a name for your portfolio and start investing!',
    portfolioName: 'Portfolio name',
    continue: 'Continue',
    yourBalance: 'Your balance',
    invested: 'Invested',
    editInfo: 'You can edit the portfolio before the battle starts',
    selectAssets: 'Select assets',
    add: 'Add',
    edit: 'Edit',
    done: 'Done',
    emailError: 'Wrong email address',
    tooLongName: 'Too long name',
    publish: 'Publish',
    portfolioExist: 'This portfolio name already exists, please change the name and try again',
    portfolioCreated: 'Your portfolio is successfully published! Until the Battle started, you can change it',
    emailInfo: 'Almost there... You need to confirm your email to enter the Battle. We have sent an email, check it and click on the link. (If not found - check in spam folder)',
    retryAfter: 'Try again in {count} seconds',
    coinSearch: 'Coin search',
    update: 'Update',
    moniDescription: 'Is the easiest way to track your crypto portfolios and explore the market',
    getin: 'Log in',
    getinText: 'We sent you a confirmation email. Check it out now!',
    spaminfo: 'if you didn\'t find it, please check your Spam folder',
    signout: 'Sign out',
    signoutInfo: 'Are you leaving',
    begin: 'Begin',
    end: 'End',
    prize: 'Reward',
    iUnderstood: 'Niiice! Bro...Keep it on a low, but we’re cheering for you.',
    wannaBePartOfNext: 'Do you want to participate in the next one?',
    nextInfo: 'Нажми Войти и когда начнется след. баттл мы оповестим тебя. Там же ты сможешь прочитать про условия участия, бла-бла',
    archive: 'ARCHIVE',
    archiveCaption: 'find previous battles here',
    winner: 'Winner',
    dates: 'Dates',
    all: 'ALL',
    tookPart: 'TOOK PART',
    we: 'WE ARE',
    who: 'who',
    more: 'More',
    withLove: 'Made with Love',
    inProcess: 'In process',
    liveCaption: 'this means right now',
    hiChampion: 'Hey, champion',
    email: 'Email',
    upcomingBattles: 'UPCOMING BATTLES',
    upcomingBattlesCaption: 'this means really soon',
    registration: 'Registration',
    start: 'Start',
    final: 'Final',
    members: 'Members',
    takePart: 'Let’s go',
    join: 'Join',
    registrationWillBegin: 'THE REGISTRATIONS WILL START',
    battleWillBegin: 'THE BATTLE WILL START',
    registerWillEnd: 'THE REGISTRATION WILL CLOSE',
    in: 'IN',
    showMore: 'Show more',
    checkRules: 'Please! Check',
    terms: 'Terms of participation',
    termsMoreInfo: 'once again, because when you win (we believe in you!) you won’t get the prize, unless you’ve followed all the necessary steps!',
    info404: 'BACK TO MAIN PAGE',
    alldone: 'DONE',
    maxSumPopup: 'You can choose one asset for no more than $400',
    fillPortfolioName: 'Fill out the name of your portfolio',
    battleWillEnded: 'THE BATTLE WILL BE OVER IN',
    battleEnded: 'THE BATTLE IS OVER',
    battleName: 'Battle\'s<br>name',
    becomePartner: 'Become the Moni Battle Partner',
    becomePartnerShort: 'Become the partner',
    becomePartnerLogoInfo: 'Your logo could be here',
    benefits: {
        first: {
            title: 'Complex promotion',
            text: 'Subscribers of Moni and our partners will explore about your project.'
        },
        second: {
            title: 'Contacts',
            text: 'You can make useful acquaintances with interesting and promising projects during the Battle.',
        },
        third: {
            title: 'Branding',
            text: 'Exciting press event!',
        },
        fourth: {
            title: 'Content',
            text: 'Your project will bring fun for your audience to the next level.',
        },
    },
    infoForPartners: 'Send an email with description of your project to become our partner.',
    digestInfo: 'Get the 5 minute newsletter with hottest 🌶 takes on crypto news from 250+ resources. <span class="font-weight-semiBold">Every&nbsp;day</span>.',
    subscribe: 'Subscribe',
    rules: 'This battle is paid',
};
